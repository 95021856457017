<template>
  <div class="home">
    <img alt="logo" src="../assets/logo.png">
    <CompanyForm :taxRegimes="this.taxRegimes" :billerTypes="this.billerTypes" />
  </div>
</template>

<script>
import CompanyForm from '../components/company/CompanyForm.vue'

export default {
  name: 'CompanyNew',
  components: {
      CompanyForm
  },
  data: () => ({
    taxRegimes: [],
    billerTypes: []
  }),
  methods: {
    async getTaxRegimes () {
      this.$axios
      .get('main/getTaxRegimes')
      .then(response => {
        this.taxRegimes = response.data.taxRegimes
      })
      .catch(error => console.log(error))
    },
    async getBillerTypes () {
      this.$axios
      .get('main/getBillerTypes')
      .then(response => {
        this.billerTypes = response.data.billerTypes
      })
      .catch(error => console.log(error))
    }
  },
  mounted(){
    this.getTaxRegimes()
    this.getBillerTypes()
  }
}
</script>